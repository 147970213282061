<template>
    <el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text">
          <strong class="section-text">*PEP</strong> es la Persona Expuesta Políticamente, iIgualmente se catalogan como PEP las personas que tengan una sociedad conyugal, de hecho, o derecho con las personas expuestas políticamente nacionales o extranjeras, así como a sus familiares hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil.
        </p>
      </el-col>
    </el-row>

    <div class="juridico">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="300" :data="objetoInfoBeneficiarioAccionista_Juridico.Beneficiario" :span="24">

          <el-table-column width="20%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre o razón social de accionista*" width="245px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreAJ_Beneficiario" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:62px;" v-show="!validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].NombreAJ_Beneficiario.length"></div>
              <el-form-item v-for="error in validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].NombreAJ_Beneficiario" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre de beneficiario*" width="200px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreBeneficiario" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:62px;" v-show="!validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].NombreBeneficiario.length"></div>
              <el-form-item v-for="error in validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].NombreBeneficiario" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID*" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdBeneficiario" placeholder="Seleccionar" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDeIdentificacion" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].TipoIdBeneficiario.length"></div>
              <el-form-item v-for="error in validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].TipoIdBeneficiario" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="150px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionBeneficiario" @change="ValidarCamposFila(scope.$index)"/>
              <div style="height:62px;" v-show="!validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].NumeroIdentificacionBeneficiario.length"></div>
              <el-form-item v-for="error in validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].NumeroIdentificacionBeneficiario" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="¿Es PEP?*" width="135px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.EsPEPBeneficiario" placeholder="Seleccionar" @change="ValidaSiPEPAJ();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].EsPEPBeneficiario.length"></div>
              <el-form-item v-for="error in validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].EsPEPBeneficiario" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="¿Tiene vínculos familiares con PEP?*" width="175px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.VinculosFamPEP_Beneficiario" placeholder="Seleccionar" @change="ValidaSiPEPAJ();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div style="height:62px;" v-show="!validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].VinculosFamPEP_Beneficiario.length"></div>
              <el-form-item v-for="error in validacionInfoBeneficiarioAccionista_Juridico.Beneficiario.$each.$response.$errors[scope.$index].VinculosFamPEP_Beneficiario" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaBeneficiario(scope.$index);ValidaSiPEPAJ();">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaBeneficiario">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
    </el-form>
</template>
<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted } from 'vue';
import InfoBeneficiarioAccionista_JuridicoValidacion from '../../validation/InfoBeneficiarioAccionista_Juridico';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
    props: {
        scopeInfoBeneficiarioAccionista_Juridico: String,
        parametricas: {},
        infoFormularioBeneficiario: {}
    },
    setup(props) {
        
        let [objetoInfoBeneficiarioAccionista_Juridico, reglasValidacion] = new InfoBeneficiarioAccionista_JuridicoValidacion().ObtenerReglasValidacion();

        const AgregarFilaBeneficiario = () => {

            objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.push({
                NombreAJ_Beneficiario: '',
                NombreBeneficiario: '',
                TipoIdBeneficiario: '',
                NumeroIdentificacionBeneficiario: '',
                EsPEPBeneficiario: '',
                VinculosFamPEP_Beneficiario: '',
            }); 
        }

        const EliminarFilaBeneficiario = (index) => {
            objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.splice(index, 1);
        }

        const reiniciarObjetoInfoBeneficiarioAccionista_Juridico = () => {
          
          objetoInfoBeneficiarioAccionista_Juridico.Beneficiario = [];
          AgregarFilaBeneficiario();
        }

        const validacionInfoBeneficiarioAccionista_Juridico = useVuelidate(reglasValidacion, objetoInfoBeneficiarioAccionista_Juridico, { $scope: props.scopeInfoBeneficiarioAccionista_Juridico })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onMounted(() => {
          let i;

        if(props.infoFormularioBeneficiario != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioBeneficiario.beneficiario != null)
        {      
          if((props.infoFormularioBeneficiario.beneficiario.length > 0) && (Object.values(props.infoFormularioBeneficiario.beneficiario[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioBeneficiario.beneficiario.length; i++)
            {
                if(Object.values(props.infoFormularioBeneficiario.beneficiario[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.push({
                        NombreAJ_Beneficiario: props.infoFormularioBeneficiario.beneficiario[i].nombreAJ_Beneficiario,
                        NombreBeneficiario: props.infoFormularioBeneficiario.beneficiario[i].nombreBeneficiario,
                        TipoIdBeneficiario: props.infoFormularioBeneficiario.beneficiario[i].tipoIdBeneficiario,
                        NumeroIdentificacionBeneficiario: props.infoFormularioBeneficiario.beneficiario[i].numeroIdentificacionBeneficiario,
                        EsPEPBeneficiario: props.infoFormularioBeneficiario.beneficiario[i].esPEPBeneficiario,
                        VinculosFamPEP_Beneficiario: props.infoFormularioBeneficiario.beneficiario[i].vinculosFamPEP_Beneficiario
                    }); 
                }             
            }
        }
      })


        return { 
          AgregarFilaBeneficiario, 
          EliminarFilaBeneficiario,
          validacionInfoBeneficiarioAccionista_Juridico, objetoInfoBeneficiarioAccionista_Juridico, reiniciarObjetoInfoBeneficiarioAccionista_Juridico,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEPAJ()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.length; i++)
            {
                if(this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario[i].EsPEPBeneficiario.includes("Si") || this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario[i].VinculosFamPEP_Beneficiario.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepAJ',Result)
        },
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    },
})

</script>

