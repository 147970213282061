<template>
  <el-form label-position="top" label-width="auto" class="greenItem">
    <h2 class="titleDD">Información financiera</h2>
    <el-row :gutter="24" justify="space-evenly">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <p style="color:#706262">La información que ingrese a continuación debe corresponder al año fiscal inmediatamente. Ten en cuenta que esta información es necesaria para finalizar el proceso, por lo anterior no omitas el diligenciamiento de todos los campos.</p>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Ventas o ingresos operacionales">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.VentasIngresosOperacionales"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Total de activos">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.TotalActivos"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Egresos">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.Egresos"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>


      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Ingresos no operacionales">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.IngresosNoOperacionales"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Otros activos">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.OtrosActivos"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Total pasivos">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.TotalPasivos"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Total ingresos">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.TotalIngresos"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Descripción otros activos">
          <el-input v-model="objetoFinanciera.DescripcionOtrosActivos" @change="ValidarCamposLlenos()"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Activos totales">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.ActivosTotales2"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Gastos Totales">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.GastosTotales"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Gastos financieros">
          <currency-input
            :options="{
              locale: 'pt-BR',
              currency: 'COP',
              currencyDisplay: 'narrowSymbol',
            }"
            v-model="objetoFinanciera.GastosFinancieros"
            @change="ValidarCamposLlenos()"
          ></currency-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Descripción otros ingresos">
          <el-input v-model="objetoFinanciera.DescripcionOtrosIngresos" @change="ValidarCamposLlenos()"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Descripción ingresos no operacionales">
          <el-input v-model="objetoFinanciera.DescripcionIngresosNoOperacionales" @change="ValidarCamposLlenos()"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        
      </el-col> 
    </el-row>
  </el-form>
</template>

<script>
import { defineComponent, onUpdated } from "vue";
import CurrencyInput from "./CurrencyInput.vue";
import FinancieraValidacion from '@/validation/InfoFinanciera';

export default defineComponent({
  components: { CurrencyInput },
  props: {
        scopeFinanciera: String,
        FinancieraRef: {},
        infoFormularioFinanciera: {}
    },
  setup(props) {

      let [objetoFinanciera] = new FinancieraValidacion().ObtenerReglasValidacion();

      if(props.infoFormularioFinanciera != "No se encuentra diligenciamiento del formulario para el empleador consultado")
      {
      objetoFinanciera.VentasIngresosOperacionales = props.infoFormularioFinanciera.ventasIngresosOperacionales;
      objetoFinanciera.TotalActivos = props.infoFormularioFinanciera.totalActivos;
      objetoFinanciera.Egresos = props.infoFormularioFinanciera.egresos;
      objetoFinanciera.IngresosNoOperacionales = props.infoFormularioFinanciera.ingresosNoOperacionales;
      objetoFinanciera.OtrosActivos = props.infoFormularioFinanciera.otrosActivos;
      objetoFinanciera.TotalPasivos = props.infoFormularioFinanciera.totalPasivos;
      objetoFinanciera.TotalIngresos = props.infoFormularioFinanciera.totalIngresos;
      objetoFinanciera.DescripcionOtrosActivos = props.infoFormularioFinanciera.descripcionOtrosActivos;
      objetoFinanciera.ActivosTotales2 = props.infoFormularioFinanciera.activosTotales2;
      objetoFinanciera.GastosTotales = props.infoFormularioFinanciera.gastosTotales;
      objetoFinanciera.GastosFinancieros = props.infoFormularioFinanciera.gastosFinancieros;
      objetoFinanciera.DescripcionOtrosIngresos = props.infoFormularioFinanciera.descripcionOtrosIngresos;
      objetoFinanciera.DescripcionIngresosNoOperacionales = props.infoFormularioFinanciera.descripcionIngresosNoOperacionales;
      }
      

      const reiniciarObjetoFinanciera = () => {
          objetoFinanciera.VentasIngresosOperacionales = ''
          objetoFinanciera.TotalActivos = ''
          objetoFinanciera.Egresos = ''
          objetoFinanciera.IngresosNoOperacionales = ''
          objetoFinanciera.OtrosActivos = ''
          objetoFinanciera.TotalPasivos = ''
          objetoFinanciera.TotalIngresos = ''
          objetoFinanciera.DescripcionOtrosActivos = ''
          objetoFinanciera.ActivosTotales2 = ''
          objetoFinanciera.GastosTotales = ''
          objetoFinanciera.GastosFinancieros = ''
          objetoFinanciera.DescripcionOtrosIngresos = ''
          objetoFinanciera.DescripcionIngresosNoOperacionales = ''
        }

        onUpdated(() => {
            window.scrollTo(0, 0);
})

    return { objetoFinanciera, reiniciarObjetoFinanciera };
  },
  methods:{
      ValidarCamposLlenos()
      {
        var CantidadCamposLlenos = Object.values(this.objetoFinanciera).filter(x => (x !== null) && (x !== "") && (x !== undefined) && (x !== 0)).length;
        if(CantidadCamposLlenos === 6 || CantidadCamposLlenos === 12 || CantidadCamposLlenos === 18)
        {
          this.$emit('GenerarGuardadoParcial');
        }
      }
      
    }
});
</script>
