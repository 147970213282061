<template>
  <div >
    <Header></Header>
    <div class="container">
       <router-view/>
      <Spinner v-if="StateLoading" type="modern"></Spinner>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
import Spinner from "@/components/layout/Spinner.vue";
import{useState} from '@/store/helper'
export default {
    components:{
      Footer,
      Header,
      Spinner,
    },
    setup() {
         const{StateLoading} = useState(['StateLoading']);
    return{
      StateLoading
    };
  }
};
</script>