<template>
  <el-form label-position="top" label-width="auto" class="greenItem">
    <h2 class="titleDD">Información general del cliente</h2>
    <el-row :gutter="24" justify="space-evenly">
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-show="DeshabilitarCamposJuridicos != 'Jurídica'">
        <el-form-item label="Nacionalidad">
          <el-select
            v-model="objetoCliente.Nacionalidad"
            placeholder="Seleccionar"
            @change="ValidarCamposLlenos()"
            filterable
          >
            <el-option
              v-for="item in parametricas.pais"
              :key="item.paisid"
              :label="item.nombre"
              :value="item.nombre"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-show="DeshabilitarCamposJuridicos != 'Jurídica'">
        <el-form-item label="Ocupación">
          <el-select
            v-model="objetoCliente.Ocupacion"
            placeholder="Seleccionar"
            @change="ValidarCamposLlenos()"
          >
            <el-option
              v-for="item in parametricas.ocupacion"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Documento de Constitución">
          <el-select
            v-model="objetoCliente.DocumentoConstitucion"
            placeholder="Seleccionar"
            @change="ValidarCamposLlenos()"
          >
            <el-option
              v-for="item in parametricas.documentoDeConstitucion"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Número (Si aplica)">
          <el-input v-model="objetoCliente.Numero" @keypress="func.OnlyNumbers" @change="AjustarFormatoCampos(),ValidarCamposLlenos()"/>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="País de Constitución*">
          <el-select
            v-model="objetoCliente.PaisConstitucion"
            placeholder="Seleccionar" filterable
            @change="ValidarCamposLlenos()"
          >
            <el-option
              v-for="item in parametricas.pais"
              :key="item.paisid"
              :label="item.nombre"
              :value="item.nombre"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="error of validacionCliente.PaisConstitucion.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Fecha de Constitución*">
          <el-date-picker
            type="date"
            placeholder="Seleccionar"
            style="width: 100%"
            v-model="objetoCliente.FechaConstitucion"
            format="DD/MM/YYYY"
            @change="ValidarCamposLlenos()"
          />
        </el-form-item>
        <el-form-item
          v-for="error of validacionCliente.FechaConstitucion.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Registro Público (Si aplica)">
          <el-input v-model="objetoCliente.RegistroPublico" @change="ValidarCamposLlenos()"/>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="Tipo de Constitución">
          <el-select
            v-model="objetoCliente.TipoConstitucion"
            placeholder="Seleccionar"
            @change="ValidarCamposLlenos()"
          >
            <el-option
              v-for="item in parametricas.tipoDeConstitucion"
              :key="item.codigo"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <label class="subTitleDD">Actividad Económica - Principal*</label>
        <el-form-item label="Código CIIU">
          <el-select v-model="objetoCliente.CodigoCIIU1" filterable placeholder="Seleccione" @change="ValidarCamposLlenos()" >
            <el-option
              v-for="item in parametricas.actividadEconomica"
              :key="item.codigo"
              :label="item.codigo +' - '+ item.valor"
              :value="item.codigo + ' - ' + item.valor"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="error of validacionCliente.CodigoCIIU1.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <label class="subTitleDD">Actividad Económica - Secundaria</label>
        <el-form-item label="Código CIIU">
          <el-select v-model="objetoCliente.CodigoCIIU2" filterable clearable placeholder="Seleccione" @change="ValidarCamposLlenos()">
            <el-option
              v-for="item in parametricas.actividadEconomica"
              :key="item.codigo"
              :label="item.codigo +' - '+ item.valor"
              :value="item.codigo + ' - ' + item.valor"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col
        :xs="8"
        :sm="8"
        :md="8"
        :lg="8"
        :xl="8"
        style="padding: 0px 0 0 12px"
      >
        <el-form-item label="¿La empresa es entidad vigilada?*"> </el-form-item>
      </el-col>

      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <el-select
          v-model="objetoCliente.EntidadVigilada"
          placeholder="Seleccionar"
          class="selectDD"
          @change="ValidarCamposLlenos()"
        >
          <el-option
            v-for="item in parametricas.generico"
            :key="item.codigo"
            :label="item.valor"
            :value="item.valor"
          />
        </el-select>
        <el-form-item
          v-for="error of validacionCliente.EntidadVigilada.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col
        :xs="8"
        :sm="8"
        :md="8"
        :lg="8"
        :xl="8"
        style="padding: 0px 0 0 12px"
      >
        <el-form-item
          label="¿La empresa está obligada a implementar el sistema LAFT o afines?*"
        >
        </el-form-item>
      </el-col>

      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <el-select
          v-model="objetoCliente.SistemaLAFT"
          placeholder="Seleccionar"
          class="selectDD"
          @change="ValidarCamposLlenos()"
        >
          <el-option
            v-for="item in parametricas.generico"
            :key="item.codigo"
            :label="item.valor"
            :value="item.valor"
          />
        </el-select>
        <el-form-item
          v-for="error of validacionCliente.SistemaLAFT.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item
          label="Referencia las normas que aplican y la entidad o entidades de control."
          :style="[objetoCliente.EntidadVigilada !== 'Si' ? { opacity: '0.15' } : {}]"
        >
          <el-input
            :disabled="objetoCliente.EntidadVigilada !='Si'"
            v-model="objetoCliente.EntidadControl"
            @change="ValidarCamposLlenos()"
          />
        </el-form-item>
        <el-form-item
          v-for="error of validacionCliente.EntidadControl.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item
          label="Referencia la fecha de inicio de implementación en la entidad. "
          :style="[objetoCliente.SistemaLAFT !== 'Si' ? { opacity: '0.15' } : {}]"
        >
          <el-date-picker
            type="date"
            placeholder="Seleccionar"
            style="width: 100%"
            :disabled="objetoCliente.SistemaLAFT !='Si'"
            v-model="objetoCliente.FechaImplementacion"
            format="DD/MM/YYYY"
            @change="ValidarCamposLlenos()"
          />
        </el-form-item>
        <el-form-item
          v-for="error of validacionCliente.FechaImplementacion.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col
        :xs="8"
        :sm="8"
        :md="8"
        :lg="8"
        :xl="8"
        style="padding: 0px 0 0 12px"
      >
        <el-form-item
          label="Realiza transacciones, custodia o manejo de activos virtuales *"
        >
        </el-form-item>
      </el-col>

      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <el-select
          v-model="objetoCliente.ActivosVirtuales"
          placeholder="Seleccionar"
          class="selectDD"
          @change="ValidarCamposLlenos()"
        >
          <el-option
            v-for="item in parametricas.generico"
            :key="item.codigo"
            :label="item.valor"
            :value="item.valor"
          />
        </el-select>
        <el-form-item
          v-for="error of validacionCliente.ActivosVirtuales.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"></el-col>
    </el-row>
  </el-form>
</template>

<script>
import { defineComponent, onUpdated, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import clienteValidacion from "@/validation/infoCliente";
import func from '@/validation/GeneralRules';
import { useStore } from "vuex";

export default defineComponent({
  props: {
    scopeCliente: String,
    DeshabilitarCamposJuridicos: String,
    clienteRef: {},
    parametricas: {},
    infoFormularioCliente: {}
  },
  setup(props) {

    const store = useStore();
    let [objetoCliente, reglasValidacion] =
    new clienteValidacion().ObtenerReglasValidacion();
    const validacionCliente = useVuelidate(reglasValidacion, objetoCliente, {
      $scope: props.scopeCliente,
    });

    objetoCliente.Nacionalidad = props.infoFormularioCliente.nacionalidad;
    objetoCliente.Ocupacion = props.infoFormularioCliente.ocupacion;
    objetoCliente.DocumentoConstitucion = props.infoFormularioCliente.documentoConstitucion;
    objetoCliente.Numero = props.infoFormularioCliente.numero;
    objetoCliente.PaisConstitucion = props.infoFormularioCliente.paisConstitucion;
    objetoCliente.FechaConstitucion = props.infoFormularioCliente.fechaConstitucion;
    objetoCliente.RegistroPublico = props.infoFormularioCliente.registroPublico;
    objetoCliente.TipoConstitucion = props.infoFormularioCliente.tipoConstitucion;
    objetoCliente.CodigoCIIU1 = props.infoFormularioCliente.codigoCIIU1;
    objetoCliente.CodigoCIIU2 = props.infoFormularioCliente.codigoCIIU2;
    objetoCliente.EntidadVigilada = props.infoFormularioCliente.entidadVigilada;
    objetoCliente.EntidadControl = props.infoFormularioCliente.entidadControl;
    objetoCliente.SistemaLAFT = props.infoFormularioCliente.sistemaLAFT;
    objetoCliente.FechaImplementacion = props.infoFormularioCliente.fechaImplementacion;
    objetoCliente.ActivosVirtuales = props.infoFormularioCliente.activosVirtuales;

    onUpdated(() => {
            window.scrollTo(0, 0);
    });

    return {
      store,
      objetoCliente,
      validacionCliente,
      func
    };
  },
  methods:{
      ValidarCamposLlenos()
      {
        var CantidadCamposLlenos = Object.values(this.objetoCliente).filter(x => (x !== null) && (x !== "") && (x !== undefined)).length;
        if(CantidadCamposLlenos === 6 || CantidadCamposLlenos === 12 || CantidadCamposLlenos === 18)
        {
          this.$emit('GenerarGuardadoParcial');
        }
      },
      AjustarFormatoCampos()
      {
          this.objetoCliente.Numero = this.objetoCliente.Numero.replace(/\D/g, '');
      }
      
    }
});
</script>