<template>
<el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Información complementaria PEP</h1>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="24">
        <p class="pep-text"> Incluir la siguiente información, nombre y número de identificación: </p>
        <p class="pep-text">
          <ol>
            <li> Los cónyuges o compañeros permanentes del PEP </li>
            <li> Los familiares de las PEP, hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil. </li>
            <li> Los asociados de un PEP, cuando el PEP sea socio de, o esté asociado a, una persona jurídica y, además, sea propietario directa o indirectamente de una participación superior al 5% de la persona jurídica, o ejerza el control de la persona jurídica, en los términos del artículo 261 del Código de Comercio. </li>
          </ol>
        </p>
      </el-col>
    </el-row>
    <div class="pepInfoLegal">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="250" :data="objetoInfoRepresentantePep.RLPep" :span="24">

          <el-table-column width="40%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
                 <div v-if="HabilitaCamposPep" style="height:62px;"></div>
              </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre del representante*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreRLPEP" :disabled="!HabilitaCamposPep" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPep">
                <div style="height:62px;" v-show="!validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].NombreRLPEP.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].NombreRLPEP" :key="error" class="section-error-message" :hidden="!HabilitaCamposPep">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column class="section-text-color" label="Nombre de PEP*" width="220px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombrePEP" :disabled="!HabilitaCamposPep" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPep">
                <div style="height:62px;" v-show="!validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].NombrePEP.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].NombrePEP" :key="error" class="section-error-message" :hidden="!HabilitaCamposPep">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo ID*" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdPEPRL" placeholder="Seleccionar" :disabled="!HabilitaCamposPep" @change="ValidarCamposFila(scope.$index)">
               <el-option 
                v-for="item in parametricas.tipoDeIdentificacion && parametricas.tipoDeIdentificacion
                .filter(t => t.valor !== 'NIT' && t.valor !== 'TI')" 
                :key="item.codigo" 
                :label="item.valor" 
                :value="item.valor" />
              </el-select>
              <div v-if="HabilitaCamposPep">
                <div style="height:62px;" v-show="!validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].TipoIdPEPRL.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].TipoIdPEPRL" :key="error" class="section-error-message" :hidden="!HabilitaCamposPep">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="N° ID*" width="150px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionPEPRL" :disabled="!HabilitaCamposPep" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPep">
                <div style="height:62px;" v-show="!validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPRL.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].NumeroIdentificacionPEPRL" :key="error" class="section-error-message" :hidden="!HabilitaCamposPep">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de vínculo*" width="135px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.TipoVinculoPEPRL" :disabled="!HabilitaCamposPep" @change="ValidarCamposFila(scope.$index)"/>
              <div v-if="HabilitaCamposPep">
                <div style="height:62px;" v-show="!validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].TipoVinculoPEPRL.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].TipoVinculoPEPRL" :key="error" class="section-error-message" :hidden="!HabilitaCamposPep">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Tipo de PEP*" width="135px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoPEPRL" placeholder="Seleccionar" :disabled="!HabilitaCamposPep" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDePep" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
              <div v-if="HabilitaCamposPep">
                <div style="height:62px;" v-show="!validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].TipoPEPRL.length"></div>
              <el-form-item v-for="error in validacionInfoRepresentantePep.RLPep.$each.$response.$errors[scope.$index].TipoPEPRL" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaRLPEP(scope.$index)">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
                <div style="height:62px;"></div>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaRLPEP" :disabled="!HabilitaCamposPep">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
</el-form>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted } from 'vue';
import InfoRepresentantePepValidacion from '../../validation/InfoRepresentantePep';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
props: {
        scopeRepresentantePep: String,
        HabilitaCamposPep: Boolean,
        parametricas: {},
        infoFormularioRLPep: {}
    },
    setup(props) {

        let [objetoInfoRepresentantePep, reglasValidacion] = new InfoRepresentantePepValidacion().ObtenerReglasValidacion();

        const AgregarFilaRLPEP = () => {

            objetoInfoRepresentantePep.RLPep.push({
                NombreRLPEP: '',
                NombrePEP: '',
                TipoIdPEPRL: '',
                NumeroIdentificacionPEPRL: '',
                TipoVinculoPEPRL: '',
                TipoPEPRL: ''
            }); 
        }

        const EliminarFilaRLPEP = (index) => {
            objetoInfoRepresentantePep.RLPep.splice(index, 1);
        }

        const reiniciarObjetoInfoPepRepresentante = () => {
          objetoInfoRepresentantePep.RLPep = [];
          AgregarFilaRLPEP();
        }

        const validacionInfoRepresentantePep = useVuelidate(reglasValidacion, objetoInfoRepresentantePep, { $scope: props.scopeRepresentantePep })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onMounted(() => {
          let i;
          
        if(props.infoFormularioRLPep != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioRLPep.rlPep != null)
        {      
          if((props.infoFormularioRLPep.rlPep.length > 0) && (Object.values(props.infoFormularioRLPep.rlPep[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoRepresentantePep.RLPep.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioRLPep.rlPep.length; i++)
            {
                if(Object.values(props.infoFormularioRLPep.rlPep[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoRepresentantePep.RLPep.push({
                        NombreRLPEP: props.infoFormularioRLPep.rlPep[i].nombreRLPEP,
                        NombrePEP: props.infoFormularioRLPep.rlPep[i].nombrePEP,
                        TipoIdPEPRL: props.infoFormularioRLPep.rlPep[i].tipoIdPEPRL,
                        NumeroIdentificacionPEPRL: props.infoFormularioRLPep.rlPep[i].numeroIdentificacionPEPRL,
                        TipoVinculoPEPRL: props.infoFormularioRLPep.rlPep[i].tipoVinculoPEPRL,
                        TipoPEPRL: props.infoFormularioRLPep.rlPep[i].tipoPEPRL
                    }); 
                }             
            }
        }
      })


        return { 
          AgregarFilaRLPEP,  
          EliminarFilaRLPEP, 
          reiniciarObjetoInfoPepRepresentante,
          validacionInfoRepresentantePep, objetoInfoRepresentantePep,
          tiposIdentificacion
        }
    },
    methods:{
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoRepresentantePep.RLPep[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    }
})

</script>