import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DueDiligence from '../views/DueDiligence.vue'
import Dashboard from '../views/Dashboard.vue'
import UnAutorized from '../views/UnAutorized.vue'
import StopDueDiligence from '../views/StopDueDiligence.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/DueDiligence/:EmpleadorCifrado',
    name: 'DueDiligence',
    component: DueDiligence
  },
  {
    path: '/UnAutorized',
    name: 'UnAutorized',
    component: UnAutorized
  },
  {
    path: '/StopDueDiligence',
    name: 'StopDueDiligence',
    component: StopDueDiligence
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
