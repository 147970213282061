
import { onMounted } from 'vue';
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import Spinner from "@/components/layout/Spinner.vue";
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";

const images = require.context('../assets/img/', false, /\.png$/);

export default {
  name: "Dashboard",
  components: { Spinner },
  methods: {
    imgUrl: function (path: string) {
      if (!path.includes("http") && path !== '') {
        return images('./' + path)
      }
      return path;
    }
  },
  setup() {
    var urlImg = ref("");
    const store = useStore();
    let nombre = ref("BannerValidacionDebidaDiligencia.png");
    let featureFlag = ref(false);

    async function getImage() {
      const dataFF =
      {
        TokenDD: store.state.tokenDD,
        featureFlagName: 'PBI-238755'
      }
      await store.dispatch('GetFeatureFlag', dataFF).then(response => {
        featureFlag.value = response.data;
      })
        .catch((err) => {
          console.error(err);
        });

      if (featureFlag.value) {
        let ParametrosEmpleador = {
          TipoDocumento: localStorage.getItem('TipoIdenticacionDD'),
          NumeroDocumento: localStorage.getItem('NumeroIdentificacionDD'),
          TokenDD: store.state.tokenDD
        }
        await store.dispatch('ObtenerEstadoEmpleadorDD', ParametrosEmpleador).then(i => {
          if (i.data == EstadoDebidaDiligencia.AprobadaDD){
            nombre.value = "BannerAprobadaDebidaDiligencia.png";
          }
        })
      }
      consultarImagenesApiBlobStorage();
    }

    function consultarImagenesApiBlobStorage() {
      store.dispatch('GetImages', nombre.value).then(data => {
        urlImg.value = data.data;
      }).catch(error => {
        console.log(error);
      });
    }
    onMounted(() => {
      getImage();
    });
    return {
      getImage,
      urlImg,
    };
  },
};
