const GeneralRules = {
    OnlyNumbers(e: any) {
        e = (e) ? e : window.event;
        var charCode = (e.which) ? e.which : e.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            e.preventDefault();
        } else {
            return true;
        }
    },
    ValidateRegex(value:string, tipodoc: string):boolean{
        let ExpresionReg;
        switch (tipodoc) {
        //NIT
            case "NIT":
                ExpresionReg = /^(?=^(?!0)[0-9]*$)(?:.{3,10})$/     
                break;
        //Cédula de ciudadanía
            case "CC":
                ExpresionReg = /^(?=^(?!0)[0-9]*$)(?:.{3,8}|.{10})$/     
                break;
        //Cédula de extranjería
            case "CE":
                ExpresionReg = /^[1-9]{1}[0-9]{2,6}$/    
                break;
        //Tarjeta de identidad
            case "TI":
                ExpresionReg = /^(?:((?=^(?!0)[0-9]*$)(?:.{10})$)|((?=[00][0-9]|[0-9]*$)(?:.{11})$))/     
                break;
        //Pasaporte
            case "PA":
                ExpresionReg = /^(?=([a-zA-ZÑñ]|[0-9])([a-zA-ZÑñ]|[0-9]){0,13}[0-9]{2}$)(?:.{3,16})$/     
                break;
        //Permiso especial
            case "PE":
                ExpresionReg = /^[0-9]{15}$/     
                break;
            default:
                break;
        }
        let valida:boolean = ExpresionReg?.test(value) ? true:false
        return valida
        
    }
}

export default GeneralRules
