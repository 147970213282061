<template>
    <div class="header">
        <div class="row">
            <div class="header_logobox">
                <img src="../../assets/img/logo-aportes-en-linea-retina.png" alt="logo" class="header_logo">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name:"Header",
};
</script>