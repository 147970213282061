import { computed, reactive } from 'vue'
import { required, helpers, email, requiredIf } from '@vuelidate/validators'
import GeneralRules from '@/validation/GeneralRules'
import { useStore } from "vuex";
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";

class ContactoValidacion {

    public ObtenerReglasValidacion() {

        const objetoContacto = reactive({
            Data:{
                TipoPersona: '',
                TipoDocumento: '',
                Nombre: '',
                No_Identificacion: '',
                dv: '',
                ClaseSociedad: '',
                TipoEmpresa: '',
                OtroTipoEmpresa: '',
                Direccion: '',
                Telefono: '',
                Extension: '',
                Celular: '',
                Ciudad: '',
                Departamento: '',
                Pais: '',
                Correo: '',
                PaginaWeb: '',
                NombreContactoCliente: '',
                CargoContactoCliente: '',
                CorreoContactoCliente: '',
                TelefonoContactoCliente: '',
                ExtensionContactoCliente: '',
                CelularContactoCliente: ''
            },
            HabilitadoParaDD: false
        
        })

        const store = useStore();

        const reglasValidacion = computed(() => {
            return {
                TipoPersona: {
                    required: helpers.withMessage('Seleccione tipo persona', required)
                },
                TipoDocumento: {
                    required: helpers.withMessage('Seleccione', required)
                },
                Nombre: {
                    required: helpers.withMessage('Ingrese razón social', required)
                },
                No_Identificacion: {
                    required: helpers.withMessage('Ingrese número ID', required)
                },
                ClaseSociedad: {
                    required: helpers.withMessage('Seleccione clase sociedad', required)
                },
                TipoEmpresa: {
                    required: helpers.withMessage('Seleccione tipo empresa', required)
                },
                OtroTipoEmpresa: {
                    required: helpers.withMessage('Ingrese tipo empresa', requiredIf(objetoContacto.Data.TipoEmpresa === "Otra"))
                },
                Pais: {
                    required: helpers.withMessage('Seleccione país', required)
                },
                Departamento: {
                    required: helpers.withMessage('Seleccione departamento', requiredIf(objetoContacto.Data.Pais === "Colombia"))
                },
                Ciudad: {
                    required: helpers.withMessage('Seleccione ciudad', requiredIf(objetoContacto.Data.Pais === "Colombia"))
                },
                Correo: {
                    email: helpers.withMessage("Ingrese un email válido", email)
                },
                CorreoContactoCliente: {
                    email: helpers.withMessage("Ingrese un email válido", email),
                    required: helpers.withMessage("El campo email es obligatorio", required)
                },
                NombreContactoCliente: {
                    required: helpers.withMessage('Ingrese Nombre de contacto', required)
                },
                CelularContactoCliente: {
                    required: helpers.withMessage('Ingrese número de Celular', required)
                }
            }
        })

        return [objetoContacto, reglasValidacion] as const;
    }
}

export default ContactoValidacion;
